import React from "react";
import { Table, ConfigProvider, Button } from "antd";
import dayjs from "dayjs";

export default function TablePage() {
  const dataSource = [
    {
      id: "客户名称",
      standard: "大悟振兴石业",
      totalPrice: "出货时间",
      note: dayjs().format("YYYY年MM月DD日"),
    },
    {
      id: "编号",
      standard: "规格",
      unit: "单位",
      volume: "体积",
      point: "扣点",
      unitPrice: "单价",
      totalPrice: "总价",
      note: "备注",
    },
    {
      id: "S1",
      standard: "900*300*100=40",
      unit: "平方米",
      volume: "1.08",
      point: "",
      unitPrice: "500.00",
      totalPrice: "502.00",
      note: "",
    },
    {
      id: "S2",
      standard: "900*300*100=40",
      unit: "平方米",
      volume: "1.08",
      point: "",
      unitPrice: "5002.00",
      totalPrice: "5022.00",
      note: "",
    },
    {
      id: "S2",
      standard: "900*300*100=40",
      unit: "平方米",
      volume: "1.08",
      point: "",
      unitPrice: "5002.00",
      totalPrice: "5022.00",
      note: "",
    },
    {
      id: "S2",
      standard: "900*300*100=40",
      unit: "平方米",
      volume: "1.08",
      point: "",
      unitPrice: "5002.00",
      totalPrice: "5022.00",
      note: "",
    },
    {
      id: "S2",
      standard: "900*300*100=40",
      unit: "平方米",
      volume: "1.08",
      point: "",
      unitPrice: "5002.00",
      totalPrice: "5022.00",
      note: "",
    },

    {
      id: "S2",
      standard: "900*300*100=40",
      unit: "平方米",
      volume: "1.08",
      point: "",
      unitPrice: "5002.00",
      totalPrice: "5022.00",
      note: "",
    },
    {
      id: "S2",
      standard: "900*300*100=40",
      unit: "平方米",
      volume: "1.08",
      point: "",
      unitPrice: "5002.00",
      totalPrice: "5022.00",
      note: "",
    },
    {
      id: "S2",
      standard: "900*300*100=40",
      unit: "平方米",
      volume: "1.08",
      point: "",
      unitPrice: "5002.00",
      totalPrice: "5022.00",
      note: "",
    },
  ];

  const columns = [
    {
      title: "编号",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "规格",
      dataIndex: "standard",
      key: "standard",
      width: 200,
      onCell: (item, index: number) => {
        console.log("index:", item, index);
        if (index === 0) {
          return { colSpan: 5 };
        }
        return {};
      },
    },
    {
      title: "单位",
      dataIndex: "unit",
      key: "unit",
      onCell: (item, index: number) => {
        console.log("index:", item, index);
        if (index === 0) {
          return { colSpan: 0 };
        }
        return {};
      },
    },
    {
      title: "方数",
      dataIndex: "volume",
      key: "volume",
      onCell: (item, index: number) => {
        console.log("index:", item, index);
        if (index === 0) {
          return { colSpan: 0 };
        }
        return {};
      },
    },
    {
      title: "扣点",
      dataIndex: "point",
      key: "point",
      onCell: (item, index: number) => {
        console.log("index:", item, index);
        if (index === 0) {
          return { colSpan: 0 };
        }
        return {};
      },
    },
    {
      title: "单价",
      dataIndex: "unitPrice",
      key: "unitPrice",
      onCell: (item, index: number) => {
        console.log("index:", item, index);
        if (index === 0) {
          return { colSpan: 0 };
        }
        return {};
      },
    },
    {
      title: "总价",
      dataIndex: "totalPrice",
      key: "totalPrice",
    },
    {
      title: "备注",
      dataIndex: "note",
      key: "note",
    },
  ];

  const onPrint = () => {
    window.print();
  };

  return (
    <div className="p-16">
      <div>
        <Button onClick={onPrint}>打印</Button>
      </div>
      <h1 className="text-center text-4xl mb-4">
        大悟县众鑫新型建材销售单
      </h1>
      <ConfigProvider
        theme={{
          token: {
            colorBorderSecondary: "#000000",
            borderRadius: 0,
          },
        }}>
        <Table
          columns={columns}
          onRow={(reocrd, index) => ({
            className: index === 1 ? "bg-slate-100 font-bold" : "",
          })}
          dataSource={dataSource}
          pagination={false}
          showHeader={false}
          bordered
        />
      </ConfigProvider>
      <div className="flex font-medium mt-2">
        <div>联系电话:</div>
        <div className="font-normal">123123123123</div>
      </div>
    </div>
  );
}
